var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":!_vm.wideLayout,"justify-center":!_vm.wideLayout,"fill-height":!_vm.wideLayout}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":_vm.wideLayout,"lg12":_vm.wideLayout,"md10":!_vm.wideLayout,"lg10":!_vm.wideLayout,"xl8":!_vm.wideLayout}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            itemsPerPageOptions: [5,10,15,25,50, 100]
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"white"}},[_c('v-toolbar-title',{staticClass:"font-weight-regular body-1"},[_vm._v(_vm._s(_vm.settings.title))]),_c('v-spacer'),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.breakPoint),expression:"breakPoint"}],attrs:{"xs4":"","md4":"","lg4":""}},[_c('v-text-field',{staticClass:"pt-4",attrs:{"label":"Išči...","append-icon":"mdi-magnify","single-line":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.btnRefresh)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar_btn_fab",attrs:{"elevation":"0","color":"secondary","fab":"","x-small":"","dark":""},on:{"click":_vm.refreshDataTable}},on),[_c('v-icon',[_vm._v("mdi-sync")])],1)]}}],null,false,948541482)},[_c('span',[_vm._v("Osveži podatke")])]):_vm._e(),(_vm.btnToggleColumns)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolbar_btn_fab",attrs:{"elevation":"0","color":"secondary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.toggleColumns()}}},on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,false,349297520)},[_c('span',[_vm._v("Filtriraj stolpce")])]):_vm._e()],1),_c('br')]},proxy:true},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"id":"dt_btn_edit","color":"success","small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Uredi")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"id":"dt_btn_delete","color":"error","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Izbriši")])])]}},{key:"item.device_name",fn:function(ref){
            var item = ref.item;
return [(item.device_name !== null && item.device_name !=='null' && item.device_name !== '')?_c('v-chip',{attrs:{"label":"","color":_vm.getDeviceColor(item.device_name),"dark":"","small":""}},[_vm._v(_vm._s(item.device_name))]):_c('span',[_vm._v(_vm._s(item.device_name))])]}},{key:"item.event_name",fn:function(ref){
            var item = ref.item;
return [(item.event_id !== null && item.event_id !=='null' && item.event_id !== '')?_c('v-chip',{attrs:{"label":"","color":_vm.getEventColor(item.event_id),"dark":"","small":""}},[_vm._v(_vm._s(item.event_name))]):_c('span',[_vm._v(_vm._s(item.event_name))])]}},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"absolute":"","indeterminate":"","height":_vm.progressHeight()}})]},proxy:true}])},[_c('template',{slot:"no-data"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.searchQuery),expression:"!searchQuery"}],staticClass:"no_data"},[_vm._v(_vm._s((this.loading) ? "Obdelava podatkov je v teku ..." : "Ni podatkov za prikaz."))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"no_data"},[_vm._v(_vm._s((this.loading) ? 'Obdelava podatkov za iskani niz "' + this.search + '" je v teku ...' : ("Ni podatkov za iskani niz »" + (this.search) + "«.")))])]),_c('template',{slot:"loading"},[_c('div',{staticClass:"no_data"},[_vm._v("Obdelava podatkov je v teku ...")])])],2)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"490"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('v-icon',{attrs:{"text":""}},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.dialog_text)+" ")],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},nativeOn:{"click":function($event){return _vm.dialogCancel()}}},[_vm._v("Prekliči")]),_c('v-btn',{attrs:{"text":"","loading":_vm.loading,"color":"success"},nativeOn:{"click":function($event){return _vm.dialogConfirm()}}},[_vm._v("Nadaljuj")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }